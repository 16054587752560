import SmaTextInput from "components/textInput/index";

export default class SmaCotizador extends HTMLElement {
    tabPhases: Array<string> = [];
    mainTitleDiv: HTMLElement = null;
    backBtn: HTMLElement = null;
    nextBtn: HTMLElement = null;
    progress: HTMLElement = null;
    phase: number = -1;
    phaseTitles: String[] = [
        "¿Qué año es tu auto?",
        "¿Qué marca es tu auto?",
        "¿Qué modelo es tu auto?",
        "¿Qué versión es tu auto?",
        "¿Qué color es tu auto?",
        "¿Qué kilometraje tiene tu auto?",
        "Por favor completa tu información",
    ];
    form: any = {
        selects: {},
        texts: {},
    };
    yearsBackDefault: number = 10;
    buttonIds: string[] = [
        "year",
        "brand",
        "model",
        "version",
        "color",
        "km",
        "last",
    ];

    static get observedAttributes() {
        return ["phase", "logo", "maxyears", "loading"];
    }

    constructor() {
        super();

        this.attachShadow({ mode: "open" });

        const wrap = document.createElement("template");
        wrap.innerHTML = require("./template.html");

        const css = document.createElement("style");
        const cssString = require("./style.shadow.scss");
        css.innerHTML = cssString.default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.mainTitleDiv = this.shadowRoot.querySelector("[main-title]");
        this.backBtn = this.shadowRoot.querySelector("[back-button]");
        this.nextBtn = this.shadowRoot.querySelector("[next-button]");
        this.progress = this.shadowRoot.querySelector(".progress");

        this.goBack = this.goBack.bind(this);
    }

    connectedCallback() {
        this.setAttribute("phase", "0");
        this.backBtn.style.display = "none";

        this.backBtn.addEventListener("click", this.goBack);
        this.nextBtn.setAttribute("next-button", this.buttonIds[0]);
        this.addSelectEventListeners();
        this.addNextButtonEventListeners();
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == "phase") {
            this.setPhase(parseInt(newValue));
        }

        if (attr == "logo") {
            const logoImg = this.shadowRoot.querySelector(".logo-img");

            logoImg.setAttribute("src", newValue);
        }

        if (attr == "maxyears") {
            this.yearsBackDefault = parseInt(newValue);

            this.setPhase(0);
        }
        if (attr == "loading") {
            this.notifyLoading(parseInt(newValue) === 1);
        }
    }

    notifyLoading(isLoading: boolean) {
        this.dispatchEvent(
            new CustomEvent("cotizador-loading", {
                detail: { isLoading: isLoading },
            })
        );
    }

    reset() {
        this.form.selects = {
            selects: {},
            texts: {},
        };
        this.tabPhases = [];
        this.setAttribute("phase", "0");
        this.calculateSelection();
    }

    setPhase(noPhase: number) {
        this.phase = noPhase;

        this.mainTitleDiv.innerHTML = "" + (this.phaseTitles[this.phase] || "");
        this.setAttribute("loading", "1");
        if( this.phase <= 0){
            this.nextBtn.style.display = "none";
        }else{
            this.nextBtn.style.display = "inline";
        }
        this.nextBtn.setAttribute("next-button", this.buttonIds[this.phase]);
        this.doExtraWork().then(() => {
            if (this.phase > 0) {
                this.backBtn.style.display = "block";
            } else {
                this.backBtn.style.display = "none";
            }
            this.hideAndShowPhases();
            this.setAttribute("loading", "0");
        });
    }

    async doExtraWork() {
        try {
            this.progress.style.width = (this.phase * 100) / 7 + "%";
            switch (this.phase) {
                case 0:
                    const yearSelect = this.shadowRoot.querySelector("#year");
                    const jsonYears = this.getYearsForSelect(
                        this.yearsBackDefault,
                        this.form.selects.year
                    );
                    yearSelect.setAttribute("options", jsonYears);
                    this.form.texts.brand = null;

                    break;
                case 1:
                    this.tabPhases[0] = this.form.selects.year.value;
                    break;

                case 2:
                    this.tabPhases[1] = this.form.texts.brand;
                    break;

                case 3:
                    this.tabPhases[2] = this.form.texts.model;
                    break;

                case 4:
                    this.tabPhases[3] = this.form.texts.version;
                    break;
                case 5:
                    this.tabPhases[4] = this.form.texts.color;
                    break;
                case 6:
                    this.tabPhases[5] = this.form.texts.km;
                    break;
                default:
                    break;
            }
        } catch (e) {
            return false;
        }

        return true;
    }

    getYearsForSelect(yearsBack: number, preselected: any) {
        const currentYear = new Date().getFullYear();
        const years: any[] = [];
        for (let i = 0; i <= yearsBack; i++) {
            const calculatedYear = currentYear - i;
            years.push({
                title: calculatedYear,
                value: calculatedYear,
                selected: preselected && preselected.value === calculatedYear,
            });
        }

        return JSON.stringify(years);
    }

    hideAndShowPhases() {
        const self = this;
        const phasesDiv = this.shadowRoot.querySelectorAll("[show-phase]");

        phasesDiv.forEach((div: HTMLElement) => {
            div.classList.remove("show");
            if (div.getAttribute("show-phase") === "" + self.phase) {
                div.classList.add("show");
            }
        });
    }

    goBack() {
        if (this.phase > 0) {
            //console.log("Going back from ", this.phase);
            this.setAttribute("phase", "" + (this.phase - 1));
            this.tabPhases.pop();
        }
        setTimeout(() => {
            this.calculateSelection();
        }, 150);
    }

    goForward() {
        //console.log("Going foward from ", this.phase);
        this.setAttribute("phase", "" + (this.phase + 1));
        setTimeout(() => {
            this.calculateSelection();
        }, 150);
    }

    addSelectEventListeners() {
        const selects = this.shadowRoot.querySelectorAll(".selector");

        selects.forEach((select: any) => {
            select.addEventListener("selectedOption", () => {
                this.form.selects[select.id] = select.selected;
                this.goForward();
            });
        });
    }

    addNextButtonEventListeners() {
        const buttons = this.shadowRoot.querySelectorAll("[next-button]");

        buttons.forEach((btn: any) => {
            btn.addEventListener("click", () => {
                const k = btn.getAttribute("next-button");
                if (k === "last") {
                    const keys = ["name", "phone", "email"];
                    let valid = true;

                    for (let i = 0; i < keys.length; i++) {
                        const kinner = keys[i];
                        const input: SmaTextInput = this.shadowRoot.querySelector(
                            `#${kinner}`
                        );
                        this.form.texts[kinner] = input.getValue();
                        if (
                            kinner !== "email" ||
                            (kinner === "email" && !input.invalid)
                        ) {
                            input.markAsInvalid(this.form.texts[kinner] === "");
                        }
                        valid = valid && !input.invalid;
                    }
                    if (valid) {
                        this.submitForm();
                    }
                } else {
                    const input: SmaTextInput = this.shadowRoot.querySelector(
                        `#${k}`
                    );
                    this.form.texts[k] = input.getValue();
                    input.markAsInvalid(this.form.texts[k] === "");
                    if (!input.invalid) {
                        this.goForward();
                    }
                }
            });
        });
    }

    calculateSelection() {
        let band = this.shadowRoot.querySelector("[current-selection]");
        let bandText = "";

        this.tabPhases.forEach((fase) => {
            bandText += `> ${fase} `;
        });

        if (this.tabPhases.length > 0) {
            bandText = bandText.substr(1);
        }

        band.innerHTML = bandText;
    }

    async submitForm() {
        this.setAttribute("loading", "1");
        try {
            await this._submitForm();
            this.setAttribute("loading", "0");
            this.setAttribute("phase", "-1");
            this.shadowRoot.querySelector(".form-submit").classList.add("show");
            this.progress.style.width = "100%";
        } catch (e) {
            this.setAttribute("loading", "0");
            this.setAttribute("phase", "6");
            alert("No pudimos guardar tus datos. Por favor intenta más tarde.")
            console.error(e);
        }
    }

    getFormForSubmit(): any {
        // return JSON.stringify({
        //     nombre: this.form.texts.name,
        //     telefono: this.form.texts.phone,
        //     email: this.form.texts.email,
        //     year: this.form.selects.year.value,
        //     marca: this.form.selects.brand.value,
        //     modelo: this.form.selects.model.value,
        //     version: this.form.selects.version.value,
        //     color: this.form.selects.color.value,
        //     kilometraje: this.form.texts.km
        // });
        return JSON.stringify({
            nombre: this.form.texts.name,
            telefono: this.form.texts.phone,
            email: this.form.texts.email,
            year: this.form.selects.year.value,
            marca: this.form.texts.brand,
            modelo: this.form.texts.model,
            version: this.form.texts.version,
            color: this.form.texts.color,
            kilometraje: this.form.texts.km,
            origin: window.location.href,
        });
    }

    async _submitForm() {
        const request = new Request(this.getServiceUrl(), {
            method: "POST",
            body: this.getFormForSubmit(),
        });
        try {
            const response: Response = await fetch(request);
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error("Something went wrong on api server!");
            }
        } catch (e) {
            throw e;
        }
    }

    getServiceUrl() {
        let url = "//autouno.com/";

        if (
            window.location.href.includes("localhost") ||
            window.location.href.includes("127.0.0.1")
        ) {
            url = "//localhost:8000//";
        }

        return url + "panel/api/contactoModal";
    }
}
