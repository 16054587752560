export interface SmaSelectOption {
    title: string;
    value: string;
    selected: boolean;
}

export default class SmaSelect extends HTMLElement {

    selectElement: HTMLElement = null;
    selected: SmaSelectOption = null;

    static get observedAttributes() {
        return ["options"];
    }



    constructor() {
        super();

        this.attachShadow({ mode: 'open' });

        const wrap = document.createElement("template");
        wrap.innerHTML = require('./template.html');

        const css = document.createElement('style');
        css.innerHTML = require("./style.shadow.scss").default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.selectElement = this.shadowRoot.querySelector('select');

        // this.mainTitleDiv = this.shadowRoot.querySelector('[main-title]');
        // this.backBtn = this.shadowRoot.querySelector('[back-button]');

        //this.selectOption = this.selectOption.bind(this);
    }

    connectedCallback() {
        // this.setAttribute('phase','0');
        // this.backBtn.style.display = 'none';

        if (!this.hasAttribute('type')) {
            this.setAttribute('type', 'default');
        }

        this.selectElement.addEventListener('change', () => {
            // @ts-ignore
            const option = JSON.parse(this.selectElement.options[this.selectElement.selectedIndex].dataset.value);
            this.selectOption(option);
        });
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == 'options') {
            this.setOptions(JSON.parse(newValue));
        }

        // if (attr == 'preselected') {
        //     setTimeout( () => {
        //         this.preselectOption(newValue);
        //     },150);
        //     //this.setOptions(JSON.parse(newValue));
        // }
    }

    preselectOption(option: string) {
        // @ts-ignore
        const options = this.selectElement.querySelectorAll('option');
        console.log(options);
        if (options && options.length > 0) {
            options.forEach((opt: any) => {
                if (opt.value == option) {
                    opt.setAttribute('selected', 'selected');
                } else {
                    opt.removeAttribute('selected');
                }
            });
        }
    }

    setOptions(options: SmaSelectOption[]) {
        if (options && options.length > 0) {
            // empty select
            this.selectElement.innerHTML = "";

            const option = document.createElement('option');
            option.value = '';
            option.setAttribute('disabled', 'disabled');
            option.setAttribute('selected', 'selected');
            option.innerHTML = 'Selecciona una opción';

            this.selectElement.appendChild(option);

            options.forEach((opt) => {
                const option = document.createElement('option');
                option.value = opt.value;
                option.innerHTML = opt.title;
                option.dataset.value = JSON.stringify(opt);
                if (opt.selected) {
                    this.selectOption(opt, false);
                }

                this.selectElement.appendChild(option);
            });
        }
    }

    selectOption(option: SmaSelectOption, notify = true) {
        // @ts-ignore
        this.selected = option;
        //this.selected = this.selectElement.options[this.selectElement.selectedIndex].value;

        if (notify) {
            const event = new CustomEvent('selectedOption');
            this.dispatchEvent(event);
        }
    }
}