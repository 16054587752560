// COMPONENTS
import SmaDialog from "components/dialog/index";
import SmaTextInput from "components/textInput/index";
import SmaPillSelect from "components/pillSelectInput/index";
import SmaSelect from "components/selectInput/index";
import SmaLoader from "components/loader/index";
import SmaCotizador from "components/cotizador/index";

// VIEWS
import SmaModalCotizadorView from "./views/modal-cotizador/index";

// Definers
customElements.define("sma-loader", SmaLoader);
customElements.define('sma-dialog', SmaDialog);
customElements.define('sma-pill-select', SmaPillSelect);
customElements.define('sma-select', SmaSelect);
customElements.define('sma-text-input', SmaTextInput);
customElements.define("sma-cotizador", SmaCotizador);
customElements.define("sma-modal-cotizador-view", SmaModalCotizadorView);

// @ts-ignore
window.sma = {
    maxYears: (years: number) => {
        const el: SmaModalCotizadorView = document.querySelector("sma-modal-cotizador-view");

        if (el) {
            el.setAttribute("maxyears", years + "");
        }
    },
    addLogo: (logo: string) => {
        const el: SmaModalCotizadorView = document.querySelector("sma-modal-cotizador-view");

        if (el) {
            el.setAttribute("logo", logo);
        }
    },
    open: () => {
        const el: SmaModalCotizadorView = document.querySelector("sma-modal-cotizador-view");

        if (el) {
            el.cotizador.reset();
            el.setAttribute("forcedialog", "1");
        }
    },
    changeVersionStyle: (version: string) => {
        const el: SmaModalCotizadorView = document.querySelector("sma-modal-cotizador-view");

        if (el) {
            el.setAttribute("vstyle", version);
        }
    }
};

document.addEventListener("DOMContentLoaded", () => {
    const wrap = document.createElement("template");
    wrap.innerHTML = require("./templates/modal.html");

    document.querySelector("body").appendChild(wrap.content);
});