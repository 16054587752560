export interface SmaPillSelectOption {
    title: string;
    value: string;
    selected: boolean;
}

export default class SmaPillSelect extends HTMLElement {

    selectElement: HTMLElement = null;
    selected: SmaPillSelectOption = null;

    static get observedAttributes() {
        return ["options"];
    }



    constructor() {
        super();

        this.attachShadow({ mode: 'open' });

        const wrap = document.createElement("template");
        wrap.innerHTML = require('./template.html');

        const css = document.createElement('style');
        css.innerHTML = require("./style.shadow.scss").default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.selectElement = this.shadowRoot.querySelector('.sma-pill-select');

        //this.selectOption = this.selectOption.bind(this);
    }

    connectedCallback() {

        this.selectElement.addEventListener('click', (e) => {
            //@ts-ignore
            const el: HTMLElement = e.target;

            if( el.classList.contains('option') ){
                //console.log(el.dataset.value);
                const valores = JSON.parse(el.dataset.value);
                this.selectOption(valores);
            }
        });
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == 'options') {
            this.setOptions(JSON.parse(newValue));
        }
    }

    setOptions(options: SmaPillSelectOption[]) {
        if (options && options.length > 0) {
            // empty select
            const optionsDiv = this.selectElement.querySelector('.options');
            optionsDiv.innerHTML = "";

            options.forEach((opt) => {
                const option = document.createElement('div');
                option.classList.add('option');
                option.dataset.value = JSON.stringify(opt);
                if( opt.selected )
                {
                    option.classList.add('selected');
                    this.selectOption(opt, false);
                }
                option.innerHTML = opt.title;

                optionsDiv.appendChild(option);
            });
        }
    }

    selectOption(option: SmaPillSelectOption, notify=true) {
        this.selected = option;
        if( notify ){
            const event = new CustomEvent('selectedOption');
            this.dispatchEvent(event);
        }
    }
}