import SmaCotizador from "components/cotizador/index";

export default class SmaModalCotizadorView extends HTMLElement {
    mainTitleDiv: HTMLElement = null;
    dialog: HTMLElement = null;
    cotizador: SmaCotizador = null;
    phase: number = -1;

    static get observedAttributes() {
        return ["phase", "forcedialog", "logo", "maxyears", "vstyle"];
    }

    constructor() {
        super();

        this.attachShadow({ mode: "open" });

        const wrap = document.createElement("template");
        wrap.innerHTML = require("./template.html");

        const css = document.createElement("style");
        css.innerHTML = require("./style.shadow.scss");

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.cotizador = this.shadowRoot.querySelector("#cotizador");
        this.dialog = this.shadowRoot.querySelector("sma-dialog");
    }

    connectedCallback() {
        this.setAttribute("phase", "0");
        this.shouldWeShow(
            this.cotizador.getServiceUrl().includes("a1.localhost")
        );
        this.cotizador.addEventListener(
            "cotizador-loading",
            (e: CustomEvent) => {
                this.dialog.setAttribute(
                    "loading",
                    e.detail.isLoading ? "1" : "0"
                );
            }
        );
        if (!this.hasAttribute("vstyle")) this.setAttribute("vstyle", "1");
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == "forcedialog") {
            this.shouldWeShow(newValue === "1");
            this.removeAttribute("forcedialog");
        }

        if (attr == "logo") {
            const logoImg = this.shadowRoot.querySelector(".logo-img");

            logoImg.setAttribute("src", newValue);
        }

        if (attr == "maxyears") {
            this.cotizador.yearsBackDefault = parseInt(newValue);
            this.cotizador.setPhase(0);
        }

        if (attr === "vstyle") {
            this.dialog.setAttribute("vstyle", newValue);
        }
    }

    shouldWeShow(forceShow = false) {
        const timeModal = window.sessionStorage.getItem("used-car-modal");
        const timeFrame = 86400000;
        if (
            !timeModal ||
            parseInt(timeModal) <= Date.now() - timeFrame ||
            forceShow
        ) {
            window.sessionStorage.setItem("used-car-modal", Date.now() + "");
            this.dialog.setAttribute("show", "1");
        }
    }
}
