export default class SmaTextInput extends HTMLElement {
    fieldElement: HTMLElement = null;
    labelElement: HTMLElement = null;
    inputElement: HTMLElement = null;
    actualVal: string = null;
    invalid: Boolean = false;

    static get observedAttributes() {
        return ["prevalue", "label", "placeholder", "type"];
    }

    constructor() {
        super();

        this.attachShadow({ mode: "open" });

        const wrap = document.createElement("template");
        wrap.innerHTML = require("./template.html");

        const css = document.createElement("style");
        css.innerHTML = require("./style.shadow.scss").default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.fieldElement = this.shadowRoot.querySelector(".field");
        this.labelElement = this.shadowRoot.querySelector(".field__label");
        this.inputElement = this.shadowRoot.querySelector("input");

        // this.mainTitleDiv = this.shadowRoot.querySelector('[main-title]');
        // this.backBtn = this.shadowRoot.querySelector('[back-button]');

        //this.selectOption = this.selectOption.bind(this);
    }

    connectedCallback() {
        // this.setAttribute('phase','0');
        // this.backBtn.style.display = 'none';
        if (!this.hasAttribute("type")) {
            this.setAttribute("type", "text");
        }

        //this.selectElement.addEventListener('change', this.selectOption);
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == "prevalue") {
            setTimeout(() => {
                this.prefill(newValue);
            }, 150);
            //this.setOptions(JSON.parse(newValue));
        }
        if (attr == "placeholder") {
            this.inputElement.setAttribute("placeholder", newValue);
            //this.setOptions(JSON.parse(newValue));
        }
        if (attr == "label") {
            this.labelElement.innerHTML = newValue;
            //this.setOptions(JSON.parse(newValue));
        }
        if (attr == "type") {
            this.setType(newValue);
        }
    }

    prefill(prefillVal: string) {
        // @ts-ignore
        this.inputElement.value = prefillVal;
    }

    getValue() {
        // @ts-ignore
        return this.inputElement.value;
    }

    setValue(newVal: string) {
        // @ts-ignore
        this.inputElement.value = newVal;
    }

    setType(type: string) {
        this.recreateElement();
        switch (type) {
            case "number":
                this.inputElement.addEventListener("keyup", () => {
                    //console.log( this.getValue() );
                    // const code = evt.code;
                    // console.log(code,evt.key,evt);
                    // if (e) {
                    //     evt.preventDefault();
                    // }
                    let val = this.getValue();
                    let valNoNumbers = val
                        .replace(/[^0-9]/gi, "")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.setValue(valNoNumbers);
                });
                break;
            case "email":
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.inputElement.addEventListener("keyup", () => {
                    let val = this.getValue();
                    val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.markAsInvalid(!re.test(val.toLowerCase()));
                    //this.setValue(valNoNumbers);
                });
                break;
            case "telephone":
                this.inputElement.addEventListener("keyup", () => {
                    //console.log( this.getValue() );
                    // const code = evt.code;
                    // console.log(code,evt.key,evt);
                    // if (e) {
                    //     evt.preventDefault();
                    // }
                    let val = this.getValue();
                    let valNoNumbers = val
                        .replace(/[^0-9]/gi, "")
                        .replace(/\B(?=(\d{2})+(?!\d))/g, "-");
                    this.setValue(valNoNumbers);
                });
                break;
        }
        //this.inputElement.setAttribute('type', type);
    }

    recreateElement() {
        const new_element = this.inputElement.cloneNode(true);
        this.inputElement.parentNode.replaceChild(
            new_element,
            this.inputElement
        );

        this.inputElement = this.shadowRoot.querySelector("input");
    }

    markAsInvalid(isInvalid: Boolean) {
        this.invalid = isInvalid;
        if (isInvalid) {
            this.fieldElement.classList.add("invalid");
        } else {
            this.fieldElement.classList.remove("invalid");
        }
    }
}