export default class SmaDialog extends HTMLElement {

    dialogDiv: HTMLElement = null;
    overlayDiv: HTMLElement = null;
    titleDiv: HTMLElement = null;
    closeHandle: HTMLElement = null;
    loader: HTMLElement = null;

    static get observedAttributes() {
        return ["title", "show", "loading", "vstyle"];
    }

    constructor() {
        super();

        this.attachShadow({mode: 'open'});
        
        const wrap = document.createElement("template");
        wrap.innerHTML = require('./template.html');

        const css = document.createElement('style');
        css.innerHTML = require("./style.shadow.scss").default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.dialogDiv = this.shadowRoot.querySelector('[dialog]');
        this.overlayDiv = this.shadowRoot.querySelector('[overlay]');
        this.titleDiv = this.shadowRoot.querySelector('[title]');
        this.closeHandle = this.shadowRoot.querySelector('[close]');
        this.loader = this.shadowRoot.querySelector('sma-loader');

        this.close = this.close.bind(this);
    }

    connectedCallback() {
        //this.innerHTML = `<h1>Hello World.</h1>`
        if( !this.hasAttribute('loading') ){
            this.setAttribute('loading','0');
        }
        this.closeHandle.addEventListener('click', this.close);
    }

    attributeChangedCallback( attr: string, oldValue: any, newValue: any)
    {
        if( attr == 'title' ){
            this.setTitle(newValue);
        }

        if( attr == 'show' ){
            this.toggle(parseInt(newValue) === 1);
        }

        if( attr == 'loading' ){
            this.toggleLoading(parseInt(newValue) === 1);
        }

        if (attr === "vstyle") {
            this.addVersionStyle(newValue);
        }
    }

    addVersionStyle(verStyle: string) {
        const prefix = "version-";
        const classes = this.dialogDiv.className.split(" ").filter(function (c) {
            return c.lastIndexOf(prefix, 0) !== 0;
        });
        classes.push(prefix + verStyle);
        this.dialogDiv.className = classes.join(" ").trim();
    }

    setTitle( newTitle: string ){
        this.titleDiv.innerHTML = newTitle;
    }

    toggle( show: boolean ) {
        if( show ){
            this.dialogDiv.classList.add('show');
            this.center();
        }else{
            this.dialogDiv.classList.remove('show');
            this.uncenter();
        }
    }

    toggleLoading( isLoading: boolean ) {
        this.dialogDiv.classList.remove("loading");
        this.loader.setAttribute("show","0");
        
        if( isLoading ){
            this.dialogDiv.classList.add("loading");
            this.loader.setAttribute("show","1");
        }
    }

    uncenter()
    {
        setTimeout( () => {
            this.dialogDiv.style.top = `-100%`;
            this.dialogDiv.style.left = `-100%`;
        },100);
    }

    center()
    {
        setTimeout( () => {
            //let extra = window.pageYOffset;
            // if( extra === 0)
            // {
            //     extra = 150;
            // }
            // Go to Top ALWAYS
            window.scrollTo(0,0);
            this.dialogDiv.style.left = `calc( 50% - ${this.dialogDiv.offsetWidth / 2}px)`;
            //this.dialogDiv.style.top = `calc( 50% - ${this.dialogDiv.offsetHeight}px - ${extra}px)`;
            //this.dialogDiv.style.top = `50%`;
            this.dialogDiv.style.top = '200px'; // Always 200px from top
            this.dialogDiv.style.minHeight = `${this.dialogDiv.offsetHeight}px`; // as it appears the first time
            window.scrollTo(0,0);
        },100);
    }

    close()
    {
        this.setAttribute('show','0');
    }

}