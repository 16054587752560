export default class SmaLoader extends HTMLElement {
    loader: HTMLElement = null;

    static get observedAttributes() {
        return ["show"];
    }

    constructor() {
        super();

        this.attachShadow({ mode: "open" });

        const wrap = document.createElement("template");
        wrap.innerHTML = require("./template.html");

        const css = document.createElement("style");
        css.innerHTML = require("./style.shadow.scss").default;

        this.shadowRoot.appendChild(css);
        this.shadowRoot.appendChild(wrap.content);

        this.loader = this.shadowRoot.querySelector(".sma-loader");
    }

    connectedCallback() {
        if (!this.hasAttribute("show")) {
            this.setAttribute("show", "0");
        }
    }

    attributeChangedCallback(attr: string, oldValue: any, newValue: any) {
        if (attr == "show") {
            this.toggleVisibility(newValue === "1");
        }
    }

    toggleVisibility(show: Boolean) {
        this.loader.classList.remove("show");
        if (show) {
            this.loader.classList.add("show");
        }
    }
}
